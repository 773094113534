@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@100;200;300;400;500;600;700;800;900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Bebas+Neue&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800;900&display=swap');

@property --num {
    syntax: "<integer>";
    initial-value: 0;
    inherits: false;
}

.default-text-shadow {
    color: #FDD12A !important;
    /* text-shadow: 4px 4px 0 #EF2C58 !important; */
}

.invisible-input {
    border: none;
    /* Remove the border */
    outline: none;
    /* Remove the focus outline */
    background: transparent;
    /* Make background transparent */
    color: black;
    /* Visible text color */
    font-size: 16px;
    /* Adjust as needed */
    width: 100%;
}

.invisible-input::placeholder {
    color: grey;
    /* Placeholder color */
    
    font-family: Montserrat;
    font-size: 16px;
    font-weight: 500;
    line-height: 25px;
    text-align: left;

}

.invisible-textarea {
    border: none;
    /* Remove the border */
    outline: none;
    /* Remove the focus outline */
    background: transparent;
    /* Make background transparent */
    color: black;
    /* Visible text color */
    font-size: 16px;
    /* Adjust as needed */
    width: 100%;
}

.invisible-textarea::placeholder {
    color: grey;
    /* Placeholder color */
    
    font-family: Montserrat;
    font-size: 16px;
    font-weight: 500;
    line-height: 25px;
    text-align: left;
}

.invisible-textarea:focus {
    outline: none;
}

/* Swiper pagination dots */
.swiper-pagination-bullet {
    background-color: #6B5A1B !important;
    /* Warna dot nonaktif */
    opacity: 1;
  }
  
  .swiper-pagination-bullet-active {
    background-color: #FDD12A !important;
    /* Warna dot aktif */
  }

  body {
    overflow-x: hidden !important;
  }

  .swiper-wrapper {
    transition-timing-function: linear !important;
  }